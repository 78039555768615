import React from "react"

const Service = props => {
    return (
        <section className="service-wrap">
            <div className="section-title-wrap">
                <h2 className="title">サービス</h2>
                <p className="sub">Services</p>
            </div>
            <div className="contents">
                <div className="content">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_service01.jpg"
                         alt="Service 1"/>
                    <div className="title-wrap">
                        <h3 className="title">設置企画</h3>
                    </div>
                    <p className="description">ご要望に合う最適な機器を、複数のランドリー機器メーカーの中からご提案します。</p>
                </div>
                <div className="content">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_service02.jpg"
                         alt="Service 2"/>
                    <div className="title-wrap">
                        <h3 className="title">設計者との連携</h3>
                    </div>
                    <p className="description">ご担当者様と連携し、図面チェックなどサポートいたします。</p>
                </div>
                <div className="content">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_service03.jpg"
                         alt="Service 3"/>
                    <div className="title-wrap">
                        <h3 className="title">機器設置・施工</h3>
                    </div>
                    <p className="description">専門業者による確実な機器設置・施工を実施します。</p>
                </div>
                <div className="content">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_service04.jpg"
                         alt="Service 4"/>
                    <div className="title-wrap">
                        <h3 className="title">定期メンテナンス・<br />
                            アフターケア</h3>
                    </div>
                    <p className="description">設置後も継続して運営をサポートいたします。</p>
                </div>
            </div>
        </section>
    )
}
export default Service
