import React, { useEffect } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import PageLead from "../../../components/pageLead";
import Faq from "../../../components/faq";
import ContentFooter from "../../../components/contentFooter";
import EyeCatch from "../../../components/eyeCatch";
import Service from "../../../components/machine/service";
import AboutOkulab from "../../../components/machine/aboutOkulab";
import Advantage from "../../../components/machine/advantage";


const EquipmentSalesPage = () => {
    useEffect(() => {
    }, [])


    return (
        <Layout>
            <div className="equipment-sales-page">
                <PageLead sub="SERVICE"
                          title="ランドリー機器販売"
                          description="さまざまな場所にコインランドリーという付加価値を。\nOKULABのランドリー機器販売"
                          imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/mv.png"
                          linkUrl="/contact"
                />
                <div className="contents">
                    <FadeIn.Up>
                        <EyeCatch title="「ランドリースペース」が物件の価値を高めます。"
                                  description="マンションなどにランドリー機器を設置することで、多様化する利用者のニーズに応え、他との差別化を実現しています。医療機関やホテルなどにも設置が可能です。"
                                  imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_illustration.png"/>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Service />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <AboutOkulab />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Advantage />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Faq data='[
                    {
                        "q": "新設ではない既存の建物にも設置できますか？",
                        "a": "インフラ設備や建築構造の条件によりますが、物件に合わせて機器のご提案が可能です。"
                    },
                    {
                        "q": "納期はどのくらいですか？",
                        "a": "ご発注から設置まで、1ヶ月ほどお時間をいただいています。"
                    },
                    {
                        "q": "ランドリー機器設備の設計経験がないのですが、設計のサポートをお願いできますか。",
                        "a": "設備要件の提供、設計図書確認などの対応が可能です。"
                    },
                    {
                        "q": "どのメーカーの機器を扱っていますか？",
                        "a": "AQUA、TOSEI、エレクトロラックスの機器の取り扱い実績がございます。"
                    },
                    {
                        "q": "アフターサポートはお願いできますか？",
                        "a": "OKULABオーナーズサポートのサービス内容の提供が可能です。"
                    }
                    ]'
                        />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <ContentFooter
                            description="ランドリースペース設置をご検討の方へ、無料相談も受付中です。\nお気軽にお問い合わせください。"
                            buttonText="お問い合わせ"
                            href="/contact"
                        />
                    </FadeIn.Up>
                </div>
            </div>
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="ランドリー機器販売"
        description="マンションや医療施設、ホテルなどにランドリースペース設置を検討されている方へ。OKULABのランドリー機器販売なら、企画、設計サポート、機器設置、施工までトータルでサポート可能です。"
        topFlag={true}
    />
)

export default EquipmentSalesPage
