import React from "react"

const AboutOkulab = props => {
    return (
        <section className="about-wrap">
            <h2>
                <span className="title">OKULABについて</span><span className="sub">About OKULAB</span>
                <hr className="title-underline" />
            </h2>

            <div className="contents">
                <div className="row">
                    <div className="text-wrap">
                        OKULABは2016年の創業以来、200店舗以上のコインランドリーを出店。<br />
                        独自の設計開発を行いながら、他社ブランドの運営サポートも担い、業界を牽引してきました。<br />
                        企画から日々の運営まで、すべての工程を網羅したトータルでのサポートが可能です。
                    </div>
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_baluko.jpg"
                             alt="Baluko"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutOkulab
