import React from "react"
import ReadMoreButton from "../readMoreButton";

const Advantage = props => {
    return (
        <section className="advantage-wrap">
            <h2>
                <span className="title">OKULABの強み</span><span className="sub">OKULAB's Advantage</span>
                <hr className="title-underline"/>
            </h2>

            <div className="contents">
                <div className="row">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_planning.jpg"
                             alt="Planning"/>
                    </div>
                    <div className="text-wrap">
                        <h3>企画力</h3>
                        <h4>
                            ランドリー機器設置には幅広い専門知識が必要です。<br className="pc" />
                            重要な項目をトータルでサポートします。
                        </h4>
                        <ul>
                            <li>
                                使用用途に合わせた機器選定
                            </li>
                            <li>
                                法令・機器要件を踏まえた設計フォロー
                            </li>
                            <li>
                                洗剤・水量・回転数までコース設定をカスタマイズ
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/equipment_sales/ms_service04.jpg"
                             alt="Support"/>
                    </div>
                    <div className="text-wrap">
                        <h3>継続サポート</h3>
                        <h4>
                            設置後も良好な使用環境を維持できるアフターケアを提供できます。販売後も継続してコインランドリーの運営をサポートします。
                        </h4>
                        <ul>
                            <li>
                                洗剤・備品・消耗品サプライ
                            </li>
                            <li>
                                コールセンター・清掃・集金
                            </li>
                            <li>
                                定期メンテナンス
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="advantage-footer">
                <h3>自社ブランド「Baluko Laundry Place」で培ったノウハウを生かし、<br />
                    デザイン性とユーザビリティの高いランドリースペースのご提案も可能です。</h3>
                <ReadMoreButton title="詳しく見る" href="/services/startup"/>
            </div>
        </section>
    )
}
export default Advantage
